export const environment = {
  production: false,
  baseHref: '/my-team/',
  clientId: '7565d8f5-442a-45cd-aa21-4bad517c7744',
  backendApiScope: 'api://929b9909-43c2-4582-ba52-0167c9967a07/access_as_user',
  apiResourceUri: 'https://myteam-api-qa.amgreetings.com/myteam-service/api',
  googleMapsApi: 'AIzaSyDQvR0FIt2qTkts_a9OrpEDHvdKhKdcepE',
  cloudfrontKey: 'cloud-front-secured-url',
  cloudFrontRequests: '.cloudfront.net/',
  awsS3PhotoPath: 'photocapture_qas/',
  powerBiUrl: 'https://app.powerbi.com/Redirect?action=OpenApp&appId=dad8e6a3-839b-40a5-ba88-818a1166f58d&ctid=6b29189e-3d5d-4d76-970c-abe758468ae4',
  chat: {
    apiKey: 'mgj7jg3273je',
    url: 'https://myteam-api-qa.amgreetings.com/assets/img/group_chat.png'
  },
  appInsights: {
    instrumentationKey: 'b82c3a17-e454-492a-8f19-e559140a567f'
  },
  maxFileUploadSize: 10
};
